export const ExternalSwitches = {
  WEB_ATTRIBUTION_SURVEY: "web_attribution_survey_v2",
  WEB_EXTERNAL_HOMEPAGE_EXPERIMENT_2025: "web_external_homepage_experiment_2025_v1",
  WEB_EXTERNAL_LOGIN_OIDC_REDIRECT: "web_external_login_oidc_redirect_v1",
  WEB_EXTERNAL_LOGGEDOUT_COMBINED_A11Y_FIXES: "web_external_loggedout_combined_a11y_fixes",
  WEB_EXTERNAL_LOGGEDOUT_LOGIN_A11Y_FIXES: "web_external_loggedout_login_a11y_fixes",
} as const;

export const LiteSwitches = {
  WEB_LITE_REDIRECT_EMAIL_VERIFICATION: "web_lite_redirect_email_verification_v3",
} as const;
export const TeacherSwitches = {} as const;

export const featureSwitches = Object.values({ ...ExternalSwitches, ...TeacherSwitches, ...LiteSwitches });

export const defaultFSValues = featureSwitches.reduce((acc, fs) => ({ ...acc, [fs]: "off" }), {});
